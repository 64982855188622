<template>
  <div class="renewal-modal">
    <a-modal
      v-model="renewalModal"
      title="续订"
      :maskClosable="false"
      width="1400px"
      @ok="renewalSubmit"
      @cancel="handleModalCancel"
    >
      <a-table
        rowKey="sequenceNbr"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
      >
        <template slot="sortNo" slot-scope="text">
          {{ text }}
        </template>
        <template slot="userType" slot-scope="text">
          {{ text == '1' ? '个人用户' : '企业用户' }}
        </template>
        <template slot="user" slot-scope="text, record">
          {{ record.callUserName }}/{{ record.tel }}
        </template>
        <template slot="propductName"> 计价软件 </template>

        <template slot="month" slot-scope="text, record">
          <a-select
            v-model="record.month"
            style="width: 100%"
            placeholder="使用时长"
            allowClear
          >
            <a-select-option value="1">一个月</a-select-option>
            <a-select-option value="3">一季度</a-select-option>
            <a-select-option value="12">一年</a-select-option>
            <a-select-option value="24">两年</a-select-option>
            <a-select-option value="36">三年</a-select-option>
          </a-select>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button @click="clean(record)">移除</a-button>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  deleteHardwareapplySerials,
  examine,
  hardwareapplyPage,
  hardwareapplySerials,
} from '@/api/lock';

export default Vue.extend({
  name: 'add-modal',
  props: {
    renewalModal: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableLoading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'sortNo',
          width: '100px',
        },
        // {
        //   title: '序列',
        //   dataIndex: 'sequenceNbr',
        // },
        {
          title: '加密锁业务号',
          dataIndex: 'busId',
        },
        // {
        //   title: '设备编码',
        //   dataIndex: 'serial',
        // },
        {
          title: '用户类型',
          dataIndex: 'enterprise.userType',
          scopedSlots: { customRender: 'userType' },
        },
        {
          title: '企业名称',
          dataIndex: 'enterprise.name',
          ellipsis: true,
        },
        {
          title: '联系人',
          dataIndex: 'enterprise.callUserName',
          ellipsis: true,
        },
        {
          title: '联系电话',
          dataIndex: 'enterprise.tel',
        },
        // {
        //   title: '企业类型',
        //   dataIndex: 'enterprise.userType',
        //   scopedSlots: { customRender: 'userType' },
        // },
        {
          title: '员工信息',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
          width: '200px',
        },
        {
          title: '产品名称',
          dataIndex: 'propductName',
          scopedSlots: { customRender: 'propductName' },
        },
        {
          title: '续订期限',
          dataIndex: 'month',
          width: '120px',
          scopedSlots: { customRender: 'month' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
    };
  },
  watch: {
    renewalModal(newdata, olddata) {
      if (newdata) {
        this.hardwareapplyPage();
      }
    },
  },
  methods: {
    handleOk() {
      console.log('sdsds');
    },
    clean(record) {
      this.tableData = this.tableData.filter((e) => {
        return e.sequenceNbr != record.sequenceNbr;
      });
      this.tableData.map((item, index) => (item.sortNo = index + 1));

      /*deleteHardwareapplySerials(record.serial,0).then((res)=>{
        console.log("移除成功");
      });*/
    },
    hardwareapplyPage() {
      let serials = this.tableData.map((e) => e.serial);
      console.log(serials);
      hardwareapplySerials(serials, 0).then((res) => {
        if (res.status === 200 && res.result) {
          this.formatData(res.result);
        }
      });
    },
    formatData(list) {
      if (list.length > 0) {
        let l = this.tableData;
        for (let i = 0; i < l.length; i++) {
          for (let j = 0; j < list.length; j++) {
            if (l[i].serial == list[j].serial) {
              l[i].month = list[j].extend1;
            }
          }
        }
        this.tableData = l;
      }
    },
    handleModalCancel() {
      this.$emit('update:renewalModal', false);
    },
    renewalSubmit() {
      let data = this.tableData;
      if (data.length > 0) {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
          arr.push({
            serial: data[i].serial,
            month: data[i].month,
            applyType: 0,
          });
        }
        examine(arr).then((res) => {
          if (res.status === 200 && res.result) {
            this.$message.success('续订成功');
            this.$emit('onSuccess');
            setTimeout(() => {
              this.$emit('update:renewalModal', false);
            }, 1000);
          }
        });
      }
    },
  },
});
</script>

<style scoped></style>
