<template>
  <div class="add-modal">
    <a-modal
      v-model="lossModal"
      title="挂失"
      :maskClosable="false"
      :keyboard="false"
      width="80%"
      destroyOnClose
      dialogClass="add-modals"
      @cancel="handleModalCancel"
    >
      <div class="count">
        挂失加密锁：{{
          tableData.length - disabledList.length
        }}
        新锁写入成功：{{ disabledList.length }}
      </div>
      <a-table
        rowKey="sequenceNbr"
        :columns="columns"
        :data-source="tableData"
        :scroll="{ x: 'max-content' }"
        :pagination="false"
        :loading="tableLoading"
      >
        <template slot="sortNo" slot-scope="text">
          {{ text }}
        </template>

        <template slot="userType" slot-scope="text">
          {{ text == '1' ? '个人用户' : '企业用户' }}
        </template>
        <template slot="user" slot-scope="text, record">
          {{ record.callUserName }}/{{ record.tel }}
        </template>
        <template slot="newserial" slot-scope="text, record">
          <a-select
            v-model="record.newserial"
            style="width: 120px"
            placeholder="选择设备"
            allowClear
          >
            <a-select-option
              v-for="item in newsns"
              v-bind:key="item"
              :value="item"
              >{{ item }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="status" slot-scope="text, record">
          <span
            v-if="disabledList.includes(record.sequenceNbr)"
            class="colorGreen"
            >写入成功</span
          >
          <span v-if="newsns.length === 0" class="colorRed"
            >未检测到加密锁</span
          >
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button @click="clean(record)" style="margin: 0 5px">移除</a-button>
          <a-button
            @click="handleChange(record, 1)"
            :disabled="disabledList.includes(record.sequenceNbr)"
            >重新写入</a-button
          >
        </template>
      </a-table>
      <template slot="closeIcon"> </template>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleModalCancel">
          关闭
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { deleteHardwareapplySerials, examinelost } from '@/api/lock';
import axios from 'axios';

const request = axios.create({
  baseURL: 'http://localhost:20002/controller/',
  timeout: 10000,
});
let timter = null;
export default Vue.extend({
  name: 'add-modal',
  props: {
    lossModal: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newsns: [],
      form: {
        name: '',
      },
      timter: null,
      disabledList: [],
      tableLoading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'sortNo',
          width: '100px',
        },
        // {
        //   title: '序列',
        //   dataIndex: 'sequenceNbr',
        //   width: '200px',
        // },
        {
          title: '加密锁业务号',
          dataIndex: 'busId',
        },
        {
          title: '加密锁设备号',
          dataIndex: 'serial',
        },
        {
          title: '用户类型',
          dataIndex: 'enterprise.userType',
          scopedSlots: { customRender: 'userType' },
        },
        {
          title: '企业名称',
          dataIndex: 'enterprise.name',
        },
        {
          title: '联系人',
          dataIndex: 'enterprise.callUserName',
        },
        {
          title: '联系电话',
          dataIndex: 'enterprise.tel',
        },

        {
          title: '员工信息',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
        },
        {
          title: '新设加密锁设备号',
          dataIndex: 'newserial',
          width: '120px',
          scopedSlots: { customRender: 'newserial' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  watch: {
    lossModal(nvalue, _ovalue) {
      console.log('🚀 ~ file: add-modal.vue:270 ~ showModal ~ nvalue:', nvalue);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      if (nvalue) {
        timter = setInterval(() => {
          this.getLoaclFn();
        }, 5000);
      } else {
        clearInterval(timter);
      }
    },
  },
  destroyed() {
    if (timter) {
      clearInterval(timter);
    }
  },
  methods: {
    clean(record) {
      this.tableData = this.tableData.filter((e) => {
        return e.sequenceNbr != record.sequenceNbr;
      });
      this.tableData.map((item, index) => (item.sortNo = index + 1));
      /* deleteHardwareapplySerials(record.serial, 0).then((res) => {
        console.log('移除成功');
      });*/
    },
    getLoaclFn() {
      request
        .request({
          url: `/manager/getSn`,
          method: 'GET',
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.newsns = res.data.data;
          }
        });
    },
    handleModalCancel() {
      if (this.tableData.length - this.disabledList.length === 0) {
        this.$emit('update:lossModal', false);
      } else {
        this.showConfirm();
      }
    },
    showConfirm() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this;
      let content = `当前共选择挂失加密锁${
        this.tableData.length
      }个。挂失并写锁成功${this.disabledList.length}个，待写入${
        this.tableData.length - this.disabledList.length
      }个，是否继续关闭当前页面`;
      this.$confirm({
        title: '',
        content,
        onOk() {
          self.disabledList = [];
          self.$emit('update:lossModal', false);
        },
      });
    },
    handleChange(row, type) {
      if (!row.newserial) {
        this.$message.error('请选择设备号');
        return;
      }
      let apiData = {
        sequenceNbr: row.sequenceNbr,
        serial: row.serial,
        status: type,
        newserial: row.newserial,
        applyType: 2,
      };
      examinelost(apiData).then((res) => {
        if (res.status === 200 && res.result) {
          if (type === 1) {
            this.disabledList.push(row.sequenceNbr);
            this.$message.success('挂失成功');
          } else {
            this.$message.success('移除成功');
          }
          this.$emit('updateData');
        }
      });
    },
  },
});
</script>
<style lang="less">
.add-modals {
  .ant-modal-close {
    display: none;
  }
}
</style>
<style lang="less" scoped>
.ant-form {
  display: flex;
  align-items: center;
}

.count {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #707070;
  margin-bottom: 12px;
}
.colorGreen {
  color: #67cc83;
}
.colorRed {
  color: #f64042;
}
</style>
