import { render, staticRenderFns } from "./loss-modal.vue?vue&type=template&id=fb4518e8&scoped=true&"
import script from "./loss-modal.vue?vue&type=script&lang=js&"
export * from "./loss-modal.vue?vue&type=script&lang=js&"
import style0 from "./loss-modal.vue?vue&type=style&index=0&lang=less&"
import style1 from "./loss-modal.vue?vue&type=style&index=1&id=fb4518e8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb4518e8",
  null
  
)

export default component.exports