






















































































































































































































import Vue from 'vue';
import moment from 'moment';
import AddModal from '@/views/lock/add-modal.vue';
import RenewalModal from '@/views/lock/renewal-modal.vue';
import LossModal from '@/views/lock/loss-modal.vue';
import UnbindModal from '@/views/lock/unbind-modal.vue';
import { Modal } from 'ant-design-vue';
import {
  hardwareapplyPage,
  hardwarepool,
  hardwarepoolGroupStatus,
} from '@/api/lock';

export default Vue.extend({
  name: 'userManageList',
  components: { AddModal, RenewalModal, LossModal, UnbindModal },
  data() {
    return {
      defaultStatus: [],
      tableLoading: false,
      showModal: false,
      renewalModal: false,
      lossModal: false,
      unbindModal: false,
      searchForm: {
        name: '',
        userType: '',
        status: [],
        busId: '',
        serial: null,
        callUserName: '',
        tel: '',
      },
      propsData: [] as any,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      userManageColumns: [
        {
          title: '序号',
          dataIndex: 'sortNo',
        },
        {
          title: '加密锁业务号',
          dataIndex: 'busId',
          width: '130px',
        },
        {
          title: '加密锁设备号',
          width: '120px',
          dataIndex: 'serial',
        },
        {
          title: '用户类型',
          width: '120px',
          dataIndex: 'enterprise.userType',
          scopedSlots: { customRender: 'userType' },
        },
        {
          title: '企业名称',
          width: '120px',
          dataIndex: 'enterprise.name',
          scopedSlots: { customRender: 'enterpriseName' },
        },
        {
          title: '联系人',
          width: '100px',
          dataIndex: 'enterprise.callUserName',
        },
        {
          title: '联系电话',
          dataIndex: 'enterprise.tel',
        },
        {
          title: '员工信息',
          dataIndex: 'callUserName',
          scopedSlots: { customRender: 'callUserName' },
        },
        {
          title: '挂失/解绑',
          dataIndex: 'lossOfUnbind',
          scopedSlots: { customRender: 'lossOfUnbind' },
        },
        {
          title: '购买时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '已购服务',
          dataIndex: 'pro',
          scopedSlots: { customRender: 'pro' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      userManageData: [] as any,
      ZBT_DEFAULT: 0,
      ZBT: 0,
      ZBT_WEB: 0,
      tableData: [], // 续订、解绑、挂失数据
      selectedRowKeys: [],
      selectedData: [],
      onSelectedData: [],
      groupStatus: [],
    };
  },
  created() {
    this.getGroupStatus();
  },
  methods: {
    moment,
    getGroupStatus() {
      hardwarepoolGroupStatus().then(
        (res: { status: number; result: { [x: string]: any } }) => {
          let arry: any = [];
          if (res.status === 200 && res.result) {
            arry.push({
              value: '1',
              label: '应用中(' + (res.result['1'] ? res.result['1'] : 0) + ')',
            });
            arry.push({
              value: '91',
              label:
                '待续订(' + (res.result['91'] ? res.result['91'] : 0) + ')',
            });
            arry.push({
              value: '92',
              label:
                '待解绑(' + (res.result['92'] ? res.result['92'] : 0) + ')',
            });
            arry.push({
              value: '93',
              label:
                '待挂失(' + (res.result['93'] ? res.result['93'] : 0) + ')',
            });
          }
          this.groupStatus = arry;
          let newarr = [] as any;
          // arry.forEach((item) => {
          //   newarr.push(item.value);
          // });
          this.defaultStatus = newarr;

          this.searchForm.status = newarr;
          this.hardwarepool();
        }
      );
    },
    handleTableChange(pagination: { current: any; pageSize: any }) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.hardwarepool();
    },
    hardwarepool() {
      const postData = {
        ...this.searchForm,
        status: this.searchForm.status.join(','),
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      };
      hardwarepool(postData).then(
        (res: { status: number; result: { total: number; list: any } }) => {
          if (res.status === 200 && res.result) {
            this.pagination.total = res.result.total;
            this.userManageData = res.result.list;
            this.userManageData.forEach(
              (item: { sortNo: any }, index: number) =>
                (item.sortNo = index + 1)
            );
            console.log('--------', this.userManageData);
          }
        }
      );
    },
    onChange(selectedRowKeys: any, selectedRows: never[]) {
      this.selectedData = selectedRows;
    },
    onSelect(record: any, selected: any, selectedRows: never[]) {
      this.selectedData = selectedRows;
    },
    onSelectAll(selected: any, selectedRows: never[], changeRows: any) {
      this.selectedData = selectedRows;
    },
    hardwareapplyPage(type: any) {
      let apiData = {
        // serial: '',
        status: '0',
        applyType: type,
      };
      hardwareapplyPage(apiData).then(
        (res: { status: number; result: { list: never[] } }) => {
          if (res.status === 200 && res.result) {
            this.tableData = res.result.list;
          }
        }
      );
    },
    dateFormat(date: string | number) {
      if (!date) {
        return null;
      }
      return moment(+date).format('YYYY-MM-DD');
    },
    handleSubmit(isInit = true) {
      if (isInit) {
        this.pagination.current = 1;
      }
      const postData = {
        ...this.searchForm,
        status: this.searchForm.status.join(','),
      };
      hardwarepool(postData).then(
        (res: { status: number; result: { list: any } }) => {
          if (res.status === 200 && res.result) {
            this.userManageData = res.result.list;
            this.userManageData.forEach(
              (item: { sortNo: any }, index: number) =>
                (item.sortNo = index + 1)
            );
          }
        }
      );
    },
    handleReset() {
      for (const key of Object.keys(this.searchForm)) {
        this.searchForm[key] = '';
      }
      this.searchForm.status = this.defaultStatus;
      this.handleSubmit();
    },

    toDetail(row: { sequenceNbr: any }) {
      this.$router.push({
        path: '/lock/lockDetail',
        query: {
          id: row.sequenceNbr,
        },
      });
    },
    jump(type: number) {
      if (type === 3) {
        this.showModal = true;
      } else if (type === 0) {
        this.renewalModal = true;
      } else if (type === 1) {
        this.unbindModal = true;
      } else if (type === 2) {
        this.lossModal = true;
      }
    },
    filterSelectedData(type: number, selectData: any) {
      // 2-已挂失，93-待挂失
      let selectedData = selectData;
      let filterTags = [];
      if (selectedData.length > 0) {
        //过滤掉所有待挂失的
        filterTags = selectedData.filter((tag: any) => tag.status != 2);
        if (type != 2) {
          filterTags = filterTags.filter((tag: any) => tag.status != 93);
          this.onSelectedData = filterTags;
        } else {
          this.onSelectedData = filterTags;
        }
        this.propsData = [...this.onSelectedData];

        // filterTags = selectedData.filter((tag: any) => tag.status != 93);
        // this.onSelectedData = filterTags;
        this.propsData.map(
          (item: { sortNo: any; newserial: null }, index: number) => {
            item.sortNo = index + 1;
            type === 2 ? (item.newserial = null) : '';
          }
        );
      }
    },
    showForm(type: number) {
      if (this.selectedData.length < 1 && type != 3) {
        return this.$message.error('请选择数据');
      }
      const postData = {
        ...this.searchForm,
        status: this.searchForm.status.join(','),
      };
      hardwarepool(postData).then(
        (res: { status: number; result: { list: any } }) => {
          if (res.status === 200 && res.result) {
            let list = [] as any;
            this.userManageData = res.result.list;
            this.userManageData.forEach(
              (item: { sortNo: any }, index: number) =>
                (item.sortNo = index + 1)
            );

            this.selectedData.map((item: { sequenceNbr: any }) => {
              let arr = this.userManageData.filter(
                (a: { sequenceNbr: any }) => a.sequenceNbr === item.sequenceNbr
              );
              if (arr.length > 0) {
                list.push(arr[0]);
              }
            });
            console.log(list, '========================');
            this.filterSelectedData(type, list);

            if (this.onSelectedData.length < 1 && type != 3) {
              return this.$message.error('请选择有效数据');
            }
            if (
              (type === 0 || type === 1) &&
              this.selectedData.length !== this.onSelectedData.length
            ) {
              Modal.warning({
                title: '请注意',
                content: '您选择数据中存在待挂失数据，系统已为您自动过滤',
                okText: '确认',
                width: 450,
                onOk: () => {
                  this.jump(type);
                  return;
                },
              });
            } else {
              this.jump(type);
            }
          }
        }
      );
    },
  },
});
